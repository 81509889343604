import React, { useState, useEffect, useRef } from "react";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
import "./styles.css";

const AudioPlayer = ({ tracks }) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [volume, setVolume] = useState(0.1);
  const [isPlaying, setIsPlaying] = useState(false);

  const { title, artist, color, image, audioSrc } = tracks[trackIndex];

  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  const currentPercentage = 1.0
    ? `${(volume / 1.0) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  const onScrub = (value) => {
    audioRef.current.volume = value;
    setVolume(value);
  };

  // const toPrevTrack = () => {
  //   if (trackIndex - 1 < 0) {
  //     setTrackIndex(tracks.length - 1);
  //   } else {
  //     setTrackIndex(trackIndex - 1);
  //   }
  // };

  // const toNextTrack = () => {
  //   if (trackIndex < tracks.length - 1) {
  //     setTrackIndex(trackIndex + 1);
  //   } else {
  //     setTrackIndex(0);
  //   }
  // };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.volume = volume;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="audio-player">
      <div className="track-info">
        <img
          className="artwork"
          src={image}
          alt={`track artwork for ${title} by ${artist}`}
        />
        <h2 className="title">{title}</h2>
        <h3 className="artist">{artist}</h3>
        <AudioControls
          isPlaying={isPlaying}
          // onPrevClick={toPrevTrack}
          // onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
        <input
          type="range"
          value={volume}
          step="0.01"
          min="0"
          max="1"
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          style={{ background: trackStyling }}
        />
      </div>
      <Backdrop
        trackIndex={trackIndex}
        activeColor={color}
        isPlaying={isPlaying}
      />
    </div>
  );
};

export default AudioPlayer;
